@import '~bourbon/app/assets/stylesheets/_bourbon.scss';

$green: #bada55;
$dark-green: shade($green, 25%);
$light-green: tint($green, 25%);
$gray: #6b6659;
$light-gray: tint($gray, 25%);
$tan: #cbcbba;
$red: #b3151a;
$blue: #dae9e5;
$yellow: #f8ca00;
$black: #000;
$white: #fff;

$base-font-size: 16px;
$base-line-height: 24px;

@mixin box-shadow {
  @include prefixer(box-shadow, 0 0 10px $black, webkit moz spec);
}

@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rhythm($scale, $font-size: $base-font-size) {
  @return ($scale * $base-line-height / $font-size) * 1em;
}

@mixin adjust-font-size-to($to-size, $lines: auto) {
  font-size: ($to-size / $base-font-size) * 1em;
  @if $lines == auto {
    $lines: ceil($to-size / $base-font-size);
  }
  line-height: rhythm($lines, $to-size);
}
